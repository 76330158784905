// import { useSelector } from "react-redux";

import { getUser } from "./localStorage";

export const useAuth = () => {
  // const { profile } = useSelector((state) => state.auth);
  const user = getUser();

  // return profile || user;
  return user;
};
