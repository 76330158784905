import React from "react";
import stLoader from "../../assets/st-loader.svg";

const LoadingComponent = ({ isLoading, error, theme }) => {
  if (isLoading) {
    return (
      <div style={{ display: "flex", alignItems: "center", minHeight: "93vh" }}>
        <div style={{ width: "100%", textAlign: "center" }}>
          <img
            src={stLoader}
            alt="Loading..."
            style={{
              width: 300,
              height: 300,
            }}
          />
          {/* <CircularProgress sx={{ color: "brandColor.500" }} /> */}
        </div>
      </div>
    );
  } else if (error) {
    return (
      <div style={{ display: "flex", alignItems: "center", minHeight: "93vh" }}>
        <div style={{ width: "100%", textAlign: "center" }}>
          Sorry, there was a problem loading the page.
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default LoadingComponent;
