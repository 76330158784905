import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import MenuIcon from '@mui/icons-material/Menu';
import moment from "moment";
import { useSnackbar } from "notistack";
import lodashIsEmpty from "lodash/isEmpty";

// material ui import
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from '@mui/material/styles';
import ClearIcon from "@mui/icons-material/Clear";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Close from "@mui/icons-material/Close";
import ReplayIcon from '@mui/icons-material/Replay';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from "@mui/icons-material/Visibility";

import { ControlledCustomReactSelect } from "../../_Reusables/CustomReactSelect";
import { ControlledInputTextField } from "../../_Reusables/InputTextField";
import { getBase64 } from "../../../utils/image";
import CustomButton from "../../_Reusables/CustomButton";
import { DialogActions } from "@mui/material";
import { getTicket, getTicketList, requestTicket, updateTicket } from "../../../api";
import { showError } from "../../../utils/api_response_swal";
import { CustomStylePagination } from "../../_Reusables/CustomPagination";
import ConfirmModal from "../../_Reusables/ConfirmModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledCell = styled((props) => <TableCell {...props} />)(
  ({ theme, isbrand = 0, noborbot = 0, iswrap = 1, issticky = 0 }) => ({
    fontSize: ".85rem",
    color:
      isbrand === 1 ? theme.palette.brandColor[900] : theme.palette.grey[700],
    borderBottom: noborbot === 1 ? "none" : "1px solid rgba(224, 224, 224, 1)",
    backgroundColor:
      isbrand === 1
        ? "#733e94"
        : issticky === 1
          ? "#fff"
          : "transparent",
    whiteSpace: iswrap === 1 ? "nowrap" : "normal",
    position: (issticky === 1 || isbrand === 1) ? "sticky" : "static",
    right: issticky === 1 ? 0 : "unset",
    top: isbrand === 1 ? 0 : "unset",
    zIndex: isbrand === 1 ? 100 : 10,
  })
);

const statusObj = {
  "staff replied": {
    text: "Staff Replied",
    type: "blue",
    color: 900,
    bgcolor: 500,
  },
  "customer replied": {
    text: "Customer Replied",
    type: "blue",
    color: 900,
    bgcolor: 500,
  },
  "employee replied": {
    text: "Employee Replied",
    type: "blue",
    color: 900,
    bgcolor: 500,
  },
  "waiting reply": {
    text: "Waiting Reply",
    type: "yellow",
    color: 900,
    bgcolor: 500,
  },
  "in progress": {
    text: "In Progress",
    type: "brandColor",
    color: 900,
    bgcolor: 500,
  },
  "on hold": {
    text: "On Hold",
    type: "grey",
    color: 100,
    bgcolor: 500,
  },
  new: {
    text: "New",
    type: "red",
    color: 900,
    bgcolor: 400,
  },
  resolved: {
    text: "Resolved",
    type: "green",
    color: 900,
    bgcolor: 500,
  },
};

const topics = [
  { name: "Dashboard Profile" },
  { name: "Announcement" },
  { name: "Time Card" },
  { name: "Time Sheet" },
  { name: "Time Sheet Report" },
  { name: "Team Attendance" },
  { name: "Task List" },
  { name: "Calendar" },
  { name: "HR Concern" },
  { name: "Pending Requests" },
  { name: "Leave Credits" },
  { name: "Profile" },
  { name: "Address" },
  { name: "Payroll Information" },
  { name: "Work Information" },
  { name: "Work Schedule" },
  { name: "Security" },
  { name: "Documents" },
  { name: "Attendance Summary" },
  { name: "Leave Application Form" },
  { name: "Certificate of Attendance Application Form" },
  { name: "Schedule Adjustment Application Form" },
  { name: "Overtime Application Form" },
  { name: "Official Business Application Form" },
  { name: "Asset" },
  { name: "My Leaves" },
  { name: "My Certificate of Attendance" },
  { name: "My Schedule Adjustments" },
  { name: "My Overtimes" },
  { name: "My Official Businesses" },
  { name: "My Assets" },
].sort((a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
});

const SupportTicketing = () => {

  const { enqueueSnackbar } = useSnackbar();
  const controller = new AbortController();

  const [open, setOpen] = useState(false);
  const [openAllTickets, setOpenAllTickets] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketRows, setTicketRows] = useState([{ name: "0000001", status: "new" }]);
  const [totalDataList, setTotalDataList] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("");
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [updateData, setUpdateData] = useState({ type: "", data: "" });

  const form = useForm({
    defaultValues: {
      topic: "",
      subject: "",
      resource_name: "",
      description: "",
      file: [],
    },
  });

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const onChangeEntries = (e) => {
    setCurrentPage(1);
    setRowsPerPage(Number(e.target.value));
  };

  const onChangeFileUploader = async (files) => {
    if (files) {
      form.clearErrors("file");
      const getBase64File = await Promise.all(
        Object.keys(files).map((i) => getBase64(files[i]))
      );
      const documents_structure = [...(files || [])].map((value, key) => {
        return {
          file: getBase64File[key],
          original_name: value?.name,
        };
      });
      const mergedArray = [...form.watch("file"), ...documents_structure].reduce((accumulator, currentObject) => {
        const existingObject = accumulator.find(obj => obj.original_name === currentObject.original_name);
        if (existingObject) {
          Object.assign(existingObject, currentObject);
        } else {
          accumulator.push({ ...currentObject });
        }
        return accumulator;
      }, []);
      form.setValue("file", documents_structure);
      const inputfile = document.getElementsByName("file")?.[0];
      inputfile.value = "";
    }
  }

  const onSubmit = async (data) => {
    try {
      setSaving(true);
      const params = {
        topic: data.topic.name,
        subject: data.subject,
        affectedResource: data.resource_name,
        message: data.description,
        files: data.file.map(item => ({
          name: item.original_name,
          file: item.file,
        })),
      }
      console.log(params);
      await requestTicket(params);
      form.reset();
      enqueueSnackbar(`Ticket successfully created.`, { variant: "success", persist: false });
    } catch (error) {
      showError(error);
      console.log(error);
    } finally {
      setSaving(false);
    }
  }

  const onUpdate = async () => {
    try {
      setSaving(true);
      const params = {
        status: updateData.type === "reopen" ? 1 : 3,
      }
      await updateTicket(updateData.data.id, params);
      setUpdateData({ type: "", data: "" });
      initialFetch();
      enqueueSnackbar(`Ticket successfully ${updateData.type === "reopen" ? "reopened" : "resolved"}.`, { variant: "success", persist: false });
    } catch (error) {
      showError(error);
      console.log(error);
    } finally {
      setSaving(false);
    }
  }

  const onView = async (data) => {
    // try {
    //   setViewing(data.id);
    //   const ticketRes = await getTicket(data.id);
    //   // form.setValue("topic", ticketRes?.data?.data?.)
    // } catch (error) {
    //   showError(error);
    //   console.log(error);
    // } finally {
    //   setViewing();
    // }
  }

  const initialFetch = async () => {
    try {
      setLoading(true);
      const params = `currentPage=${currentPage - 1}&perPage=${rowsPerPage}`;
      const leavesRes = await getTicketList(params, controller);
      setTicketRows(leavesRes?.data?.data?.rows || []);
      setTotalDataList(leavesRes?.data?.data?.count || 0);
    } catch (error) {
      showError(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (openAllTickets) {
      initialFetch();
    }
  }, [currentPage, rowsPerPage, openAllTickets]);

  return (
    <>
      <IconButton onClick={() => setOpen(true)} sx={{ padding: "4px" }}>
        <HelpOutlineIcon
          sx={{
            fontSize: 25,
            color: "common.white",
          }}
        />
      </IconButton>
      <FormProvider {...form}>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          sx={(theme) => ({
            "& .MuiDialog-paper": {
              width: 600,
              borderRadius: 2,
              padding: 0
            },
          })}
        >
          <DialogTitle
            sx={(theme) => ({
              backgroundColor: theme.palette.brandColor[500],
            })}
          >
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography
                variant="body1"
                sx={(theme) => ({
                  fontSize: ".9rem",
                  color: theme.palette.common.white,
                })}
              >
                Support Ticket
              </Typography>
              <IconButton onClick={() => { setOpen(false); form.reset() }}>
                <ClearIcon
                  sx={{
                    fontSize: 20,
                    color: "#fff",
                  }}
                />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                paddingTop: 2,
                paddingBottom: 2,
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography fontSize="1.3rem" color="brandColor.500" fontWeight={600}>Create a Concern Ticket</Typography>
                <IconButton onClick={() => setOpenAllTickets(true)}>
                  <Tooltip title="View all tickets">
                    <MenuIcon
                      sx={{
                        fontSize: 20,
                        color: "brandColor.500",
                      }}
                    />
                  </Tooltip>
                </IconButton>
              </Stack>
              <Typography color="brandColor.500" fontSize=".85rem" marginTop={1}>
                Please help us route your question to the relevant team by choosing a topic.
              </Typography>
              <Stack spacing={2} mt={4}>
                <ControlledCustomReactSelect
                  name="topic"
                  control={form.control}
                  label="Select a ticket topic"
                  options={topics}
                  optLabelKey="name"
                  optValueKey="name"
                  rules={{ required: "This field is required" }}
                  menuMaxHeight={"50vh"}
                />
                <ControlledInputTextField
                  type="text"
                  name="subject"
                  control={form.control}
                  rules={{ required: "This field is required" }}
                  size="small"
                  labelTop="Subject"
                // disabled={coaData?.id}
                />
                <ControlledInputTextField
                  type="text"
                  name="resource_name"
                  control={form.control}
                  rules={{ required: "This field is required" }}
                  size="small"
                  labelTop="Enter the name of the affected resource"
                />
                <ControlledInputTextField
                  type="text"
                  name="description"
                  control={form.control}
                  rules={{ required: "This field is required" }}
                  size="small"
                  labelTop="Describe the problem you are having in detail"
                  multiline
                  rows={6}
                // disabled={coaData?.id}
                />
                <div>
                  <Typography color={"rgb(89, 66, 155)"} fontSize=".85rem" mb={"5px"}>File Attachment:</Typography>
                  <Typography color={"rgb(89, 66, 155)"} fontSize=".85rem" mb={"5px"} fontStyle="italic">(3MB maximum file size, allowed file types: jpg, png)</Typography>
                  <Stack direction="row" alignItems="center" spacing={2} style={{ border: "1px solid #c5c4c9", borderRadius: "5px", padding: "5px" }}>
                    <FileUploader
                      multiple={true}
                      style={{ border: "unset" }}
                      types={["png", "jpg", "jpeg"]}
                      name="file"
                      handleChange={onChangeFileUploader}
                      maxSize={3}
                      onSizeError={() => {
                        form.setError("file", { message: "Document must not exceed 3MB." });
                        const inputfile = document.getElementsByName("file")?.[0];
                        inputfile.value = "";
                      }}
                      children={
                        <div {...form.register("file")}>
                          <Button component="label" sx={{ color: "brandColor.500", border: "1px solid #c5c4c9", borderRadius: "5px", backgroundColor: "rgb(240, 238, 247)", "&:hover": { backgroundColor: "brandColor.800" } }} endIcon={<CloudUploadOutlinedIcon />}>Browse</Button>
                        </div>
                      }
                    />
                    <div style={{ width: "100%", display: "flex", flexFlow: "wrap", }}>
                      {form.watch("file")?.length > 0 && form.watch("file").map((file, key) => (
                        <Stack key={key} direction="row" spacing={1} alignItems="center" sx={{ border: "1px solid", borderColor: "brandColor.600", borderRadius: "5px", padding: "5px", mr: "5px", mb: "5px", mt: "5px" }}>
                          <Typography fontSize={".85rem"} color="brandColor.500">{file?.original_name}</Typography>
                          <Close sx={{ color: "brandColor.500" }} onClick={() => form.setValue("file", form.watch("file").filter(item => item.original_name !== file?.original_name))} />
                        </Stack>
                      ))}
                    </div>
                  </Stack>
                  {form.formState.errors?.file?.message && (
                    <Typography variant="errorLabel">{form.formState.errors?.file.message}</Typography>
                  )}
                </div>
              </Stack>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <CustomButton
              text="Submit"
              colortype="brandColor"
              colorbg={1300}
              colorhover={600}
              onClick={form.handleSubmit(onSubmit)}
              disabled={saving}
              startIcon={
                (saving) && (
                  <CircularProgress size={25} sx={{ color: "brandColor.500" }} />
                )
              }
            />
          </DialogActions>
        </Dialog>
        <Dialog
          open={openAllTickets}
          TransitionComponent={Transition}
          sx={(theme) => ({
            "& .MuiDialog-paper": {
              width: "1000px",
              borderRadius: 2,
              padding: 0,
              maxWidth: "90vw"
            },
          })}
        >
          <DialogTitle
            sx={(theme) => ({
              backgroundColor: theme.palette.brandColor[500],
            })}
          >
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography
                variant="body1"
                sx={(theme) => ({
                  fontSize: ".9rem",
                  color: theme.palette.common.white,
                })}
              >
                All Tickets
              </Typography>
              <IconButton onClick={() => setOpenAllTickets(false)} disabled={loading}>
                <ClearIcon
                  sx={{
                    fontSize: 20,
                    color: "#fff",
                  }}
                />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                paddingBottom: 2,
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                mb={2}
                mt={5}
              >
                Show&emsp;
                <Select
                  value={rowsPerPage}
                  onChange={onChangeEntries}
                  disabled={loading}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
                &emsp;entries&emsp;
              </Stack>
              <TableContainer sx={{ maxHeight: "49vh" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledCell isbrand={1} onClick={() => handleRequestSort("name")}>
                        <TableSortLabel
                          active={orderBy === "name" ? true : false}
                          direction={orderBy === "name" ? order : "asc"}
                        >
                          TICKET NUMBER
                        </TableSortLabel>
                      </StyledCell>
                      <StyledCell isbrand={1} onClick={() => handleRequestSort("created_by")}>
                        <TableSortLabel
                          active={orderBy === "created_by" ? true : false}
                          direction={orderBy === "created_by" ? order : "asc"}
                        >
                          SUBJECT
                        </TableSortLabel>
                      </StyledCell>
                      <StyledCell isbrand={1} onClick={() => handleRequestSort("created_at")}>
                        <TableSortLabel
                          active={orderBy === "created_at" ? true : false}
                          direction={orderBy === "created_at" ? order : "asc"}
                        >
                          DATE CREATED
                        </TableSortLabel>
                      </StyledCell>
                      <StyledCell isbrand={1} onClick={() => handleRequestSort("status")}>
                        <TableSortLabel
                          active={orderBy === "status" ? true : false}
                          direction={orderBy === "status" ? order : "asc"}
                        >
                          STATUS
                        </TableSortLabel>
                      </StyledCell>
                      <StyledCell isbrand={1} issticky={1}>
                        ACTION
                      </StyledCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <CircularProgress
                            color="secondary"
                            style={{ margin: "20px 0" }}
                          />
                        </TableCell>
                      </TableRow>
                    ) : ticketRows.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          NO DATA FOUND
                        </TableCell>
                      </TableRow>
                    ) : (
                      stableSort(ticketRows, getComparator(order, orderBy)).map((item, index) => (
                        <TableRow key={index}>
                          <StyledCell iswrap={1}>{item.ticketId}</StyledCell>
                          <StyledCell iswrap={1}>{item.subject}</StyledCell>
                          <StyledCell iswrap={1}>{item.createdAt ? moment(item.createdAt).format("MMMM DD, YYYY") : ""}</StyledCell>
                          <StyledCell>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={"5px"}
                              sx={(theme) => ({
                                width: "fit-content",
                                background: statusObj[item.status.toLowerCase()] ?
                                  theme.palette[
                                  statusObj[item.status.toLowerCase()]
                                    .type
                                  ][
                                  statusObj[item.status.toLowerCase()]
                                    .bgcolor
                                  ] : "#666474",
                                padding: "7px 10px",
                                borderRadius: "5px",
                              })}
                            >
                              <Typography
                                variant="body1"
                                sx={(theme) => ({
                                  color: statusObj[item.status.toLowerCase()] ?
                                    theme.palette[
                                    statusObj[item.status.toLowerCase()]
                                      .type
                                    ][
                                    statusObj[item.status.toLowerCase()]
                                      .color
                                    ] : "white",
                                  fontSize: ".8rem",
                                  fontWeight: 500,
                                  width: "auto",
                                })}
                              >
                                {statusObj[item?.status?.toLowerCase()] ? statusObj[item?.status?.toLowerCase()]?.text : item?.status}
                              </Typography>
                            </Stack>
                          </StyledCell>
                          <StyledCell issticky={1} align="center">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              {/* <Tooltip title="View" onClick={() => onView(item)}>
                                <Box
                                  sx={{
                                    cursor: "pointer",
                                    backgroundColor: "blue.900",
                                    display: "flex",
                                    borderRadius: "5px",
                                    height: "35px",
                                    width: "35px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "2px solid",
                                    borderColor: "blue.600"
                                  }}
                                >
                                  <VisibilityIcon
                                    sx={{ width: "28px", color: "blue.600" }}
                                  />
                                </Box>
                              </Tooltip> */}
                              {item.status.toLowerCase() === "resolved" &&
                                <Tooltip title="Reopen" onClick={() => setUpdateData({ type: "reopen", data: item })}>
                                  <Box
                                    sx={{
                                      cursor: "pointer",
                                      backgroundColor: "brandColor.900",
                                      display: "flex",
                                      borderRadius: "5px",
                                      height: "35px",
                                      width: "35px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      border: "2px solid",
                                      borderColor: "brandColor.600"
                                    }}
                                  >
                                    <ReplayIcon
                                      sx={{ width: "28px", color: "brandColor.600" }}
                                    />
                                  </Box>
                                </Tooltip>
                              }
                              {item.status.toLowerCase() !== "resolved" &&
                                <Tooltip title="Resolved" onClick={() => setUpdateData({ type: "resolve", data: item })}>
                                  <Box
                                    sx={{
                                      cursor: "pointer",
                                      backgroundColor: "green.900",
                                      display: "flex",
                                      borderRadius: "5px",
                                      height: "35px",
                                      width: "35px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      border: "2px solid",
                                      borderColor: "green.600"
                                    }}
                                  >
                                    <CheckIcon
                                      sx={{ width: "28px", color: "green.600" }}
                                    />
                                  </Box>
                                </Tooltip>
                              }
                              {/* <Tooltip title="Cancel" onClick={() => { }}>
                                <Box
                                  sx={{
                                    cursor: "pointer",
                                    backgroundColor: "red.900",
                                    display: "flex",
                                    borderRadius: "5px",
                                    height: "30px",
                                    width: "30px",
                                    alignItems: "center",
                                    border: "2px solid",
                                    borderColor: "red.600"
                                  }}
                                >
                                  <Close
                                    sx={{ width: "28px", color: "red.600" }}
                                  />
                                </Box>
                              </Tooltip> */}
                            </Stack>
                          </StyledCell>
                        </TableRow>
                      )))}
                  </TableBody>
                </Table>
              </TableContainer>
              {!loading &&
                <CustomStylePagination
                  currentPage={currentPage}
                  totalCount={totalDataList}
                  pageSize={rowsPerPage}
                  onPageChange={onPageChange}
                />
              }
            </Box>
          </DialogContent>
        </Dialog >
        <ConfirmModal
          open={!lodashIsEmpty(updateData.data)}
          titleIcon={updateData.type === "reopen" ? <ReplayIcon sx={{ color: "#FFFFFF", fontSize: 100, }} /> : <CheckIcon sx={{ color: "#FFFFFF", fontSize: 100, }} />}
          titleText={updateData.type === "reopen" ? "Reopen" : "Resolve"}
          confirmText={`Are you sure to ${updateData.type === "reopen" ? "reopen" : "resolve"} ticket ${updateData.data.ticketId}?`}
          isTwoActions
          cancelButton={
            <CustomButton
              disabled={saving}
              onClick={() => setUpdateData({ type: "", data: "" })}
              text="Cancel"
              sx={{ width: "150px", background: "#8449ab", }}
            />
          }
          confirmButton={
            <CustomButton
              disabled={saving}
              startIcon={saving && (<CircularProgress size={20} sx={(theme) => ({ color: theme.palette.brandColor[300], })} />)}
              text={updateData.type === "reopen" ? "Reopen" : "Resolve"}
              onClick={onUpdate}
              sx={{
                width: "150px",
              }}
            />
          }
        />
      </FormProvider>
    </>
  );
};

export default SupportTicketing;
