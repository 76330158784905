import React, { useState, useRef } from "react";
import Slider from "react-animated-slider";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";

// import boxImage from "../../../assets/notifications/boxes.svg";
import { getUser } from "../../../utils/localStorage";

// import "react-animated-slider/build/horizontal.css";

const NotificationsBanner = ({ notifList, setNotifBanner }) => {
  const fromLocal = getUser();
  const sliderRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <Box
      sx={{
        padding: "10px 20px 10px 30px",
        backgroundColor: "#F0EEF7",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1} width="100%">
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Slider
              classNames={{
                slider: "custom-slider",
              }}
              duration={2000}
              // autoplay={3000}
              // infinite
              ref={sliderRef}
              previousButton={null}
              nextButton={null}
              onSlideChange={(data) => setCurrentSlide(data.slideIndex)}
              direction="vertical"
            >
              {
                notifList.map((item, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={(theme) => ({
                        color: "#000",
                        fontSize: ".8rem",
                        fontWeight: 600,
                        marginRight: "10px",
                      })}
                    >
                      {`${item.title}`}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={(theme) => ({
                        color: "#000",
                        fontSize: ".75rem",
                        marginRight: "10px",
                      })}
                    >
                      {item.highlight}
                    </Typography>
                    {/* {
                      <Typography
                        variant="body1"
                        component="span"
                        sx={(theme) => ({
                          background: "#9CD874",
                          padding: "5px 8px",
                          borderRadius: "5px",
                          fontSize: ".75rem",
                          color: "#fff",
                          cursor: "pointer",
                        })}
                        onClick={() => handleClickHere(item, index)}
                      >
                        Click here
                      </Typography>
                    } */}
                  </Stack>
                ))}
            </Slider>
          </Box>
        </Stack>
        <button
          onClick={() => setNotifBanner(false)}
          style={{
            background: "transparent",
            zIndex: 5,
            cursor: "pointer",
          }}
        >
          <CloseIcon
            sx={(theme) => ({
              color: "#000",
              fontSize: "1.4rem",
            })}
          />
        </button>
      </Stack>
    </Box>
  );
};

export default NotificationsBanner;
