import React from "react";
import { Box, Container } from "@mui/material";

// local import
import BgWithName from "../../assets/st-bg-with-name.png";
import BgLogin from "../../assets/login-bg.svg";
import Bg from "../../assets/home-background.png";
import PurpleBrandLogo from "../../assets/purple-brand-logo.svg";
import { useDataContext } from "../../context/DataContext";

const NoMenuLayout = ({ hasBgName, children }) => {
  const companyProfile = useDataContext();

  return (
    <>
      {/* <Box
        height={80}
        display="flex"
        alignItems={"center"}
        bgcolor="common.white"
        paddingX={"30px"}
      >
        <img
          // style={{ width: 180 }}
          src={
            // companyProfile?.companyProfile !== null
            //   ? "https://" + companyProfile?.companyProfile?.logo
            //   : 
              PurpleBrandLogo
          }
          alt="Satellite Teams"
        />
      </Box> */}
      <Box
        minHeight="100vh"
        sx={{
          background: hasBgName
            ? `url(${BgWithName}) no-repeat`
            : `url(${window.location.href.includes('/login') ? BgLogin : Bg}) no-repeat`,
          backgroundSize: "cover",
          backgroundPosition: "top center",
          backgroundColor: "brandColor.500"
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {children}
        </Container>
      </Box>
    </>
  );
};

export default NoMenuLayout;
