import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Loadable from "react-loadable";
import { SnackbarProvider } from "notistack";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

// local import
import PublicRoutes from "./components/routes/PublicRoutes";
import ProtectedRoutes from "./components/routes/ProtectedRoutes";
import PageLoader from "./components/_Reusables/PageLoader";
import LoadingComponent from "./components/general/LoadingComponent";
import { replaceAndSpace } from "./utils/Common";
import { DataContextProvider } from "./context/DataContext";
import "./App.css";
import { getMenu } from "./utils/localStorage";
import useDetectDevice from "./utils/useDetectDevice";
import MobileVersionImage from "./assets/mobile-version-image.png";

// const offboarding = getOffboarding() === 1;

const Login = Loadable({
  loader: () => import("./components/Login/Login"),
  loading: LoadingComponent,
});
const ForgotPassword = Loadable({
  loader: () => import("./components/Login/ForgotPassword"),
  loading: LoadingComponent,
});
const ResetPassword = Loadable({
  loader: () => import("./components/Login/ResetPassword"),
  loading: LoadingComponent,
});
const Dashboard = Loadable({
  loader: () => import("./components/Dashboard/Dashboard"),
  loading: LoadingComponent,
});
const PayrollSummary = Loadable({
  loader: () => import("./components/PayrollSummary/PayrollSummary"),
  loading: LoadingComponent,
});
const RequestAsset = Loadable({
  loader: () => import("./components/RequestAsset/RequestAsset"),
  loading: LoadingComponent,
});
const AttendanceSummary = Loadable({
  loader: () => import("./components/AttendanceSummary/AttendanceSummary"),
  loading: LoadingComponent,
});

const LeaveApplication = Loadable({
  loader: () => import("./components/LeaveApplication/LeaveApplication"),
  loading: LoadingComponent,
});

const OvertimeApplication = Loadable({
  loader: () => import("./components/OvertimeApplication/OvertimeApplication"),
  loading: LoadingComponent,
});

const UndertimeApplication = Loadable({
  loader: () => import("./components/UndertimeApplication/UndertimeApplication"),
  loading: LoadingComponent,
});

const COAApplication = Loadable({
  loader: () => import("./components/COAApplication/COAApplication"),
  loading: LoadingComponent,
});

const OfficialBusinessApplication = Loadable({
  loader: () => import("./components/OfficialBusinessApplication/OfficialBusinessApplication"),
  loading: LoadingComponent,
});

const ReimbursementApplication = Loadable({
  loader: () => import("./components/ReimbursementApplication/ReimbursementApplication"),
  loading: LoadingComponent,
});

const ScheduleAdjustment = Loadable({
  loader: () => import("./components/ScheduleAdjustment/ScheduleAdjustment"),
  loading: LoadingComponent,
});

const MyDocuments = Loadable({
  loader: () => import("./components/MyDocuments/MyDocuments"),
  loading: LoadingComponent,
});

const MyLeaves = Loadable({
  loader: () => import("./components/myRequests/MyLeaves"),
  loading: LoadingComponent,
});

const MyAssets = Loadable({
  loader: () => import("./components/myRequests/MyAssets"),
  loading: LoadingComponent,
});

const MyScheduleAdjustments = Loadable({
  loader: () => import("./components/myRequests/MyScheduleAdjustment"),
  loading: LoadingComponent,
});

const MyCOA = Loadable({
  loader: () => import("./components/myRequests/MyCOA"),
  loading: LoadingComponent,
});

const MyOvertimes = Loadable({
  loader: () => import("./components/myRequests/MyOvertimes"),
  loading: LoadingComponent,
});

const MyUndertimes = Loadable({
  loader: () => import("./components/myRequests/MyUndertimes"),
  loading: LoadingComponent,
});

const MyOfficialBusiness = Loadable({
  loader: () => import("./components/myRequests/MyOfficialBusiness"),
  loading: LoadingComponent,
});

const MyReimbursements = Loadable({
  loader: () => import("./components/myRequests/MyReimbursements"),
  loading: LoadingComponent,
});

const Profile = Loadable({
  loader: () => import("./components/Profile/ApplicantProfile"),
  loading: LoadingComponent,
});

const Referrals = Loadable({
  loader: () => import("./components/Referrals/Referrals"),
  loading: LoadingComponent,
});

const OffboardingClearance = Loadable({
  loader: () => import("./components/Offboarding/OffboardingClearance"),
  loading: LoadingComponent,
});

const NotFound = Loadable({
  loader: () => import("./components/notFound/NotFound"),
  loading: LoadingComponent,
});

const ComingSoon = Loadable({
  loader: () => import("./components/comingSoon/ComingSoon"),
  loading: LoadingComponent,
});

const setMenuComponent = (label, permission) => {
  switch (label) {
    // main
    case "dashboard": return <Dashboard />;
    case "profile": return <Navigate to="/profile" />;
    case "my-documents": return <MyDocuments />;
    // case "my-schedule": return <ComingSoon />;
    case "attendance-summary": return <AttendanceSummary />;
    case "file-a-request/leave": return <LeaveApplication />;
    case "file-a-request/certificate-of-attendance": return <COAApplication />;
    case "file-a-request/schedule-adjustment": return <ScheduleAdjustment />;
    case "file-a-request/overtime": return <OvertimeApplication />
    case "file-a-request/undertime": return <UndertimeApplication />;
    case "file-a-request/official-business": return <OfficialBusinessApplication />;
    case "file-a-request/asset": return <RequestAsset />;
    case "file-a-request/reimbursement": return <ReimbursementApplication />;
    case "my-requests/my-leaves": return <MyLeaves />;
    case "my-requests/my-certificate-of-attendance": return <MyCOA />;
    case "my-requests/my-schedule-adjustments": return <MyScheduleAdjustments />;
    case "my-requests/my-overtimes": return <MyOvertimes />;
    case "my-requests/my-undertimes": return <MyUndertimes />;
    case "my-requests/my-official-businesses": return <MyOfficialBusiness />;
    case "my-requests/my-assets": return <MyAssets />;
    case "my-requests/my-reimbursements": return <MyReimbursements />;
    // case "lms": return <ComingSoon />;
    case "referrals": return <Referrals />;
    case "offboarding-clearance": return <OffboardingClearance />;
    case "my-payroll-summary": return <PayrollSummary />;
    default:
      return <ComingSoon showCountdown={false} isInside={true} />;
  }
}
function App() {

  const os = useDetectDevice("os");
  const browser = useDetectDevice("browser");
  // const menu = getUser()?.menus || menus;
  const menu = useSelector((state) => state.auth)?.user?.menu || getMenu();

  useEffect(() => {
    if (
      os?.name === "iPad" ||
      os?.name === "iPhone" ||
      os?.name === "Android"
    ) {
      Swal.fire({
        html: `<p>Hello! To continue using the Satellite Teams Employee Website, please download the ${os?.name === "Android" ? "Android" : "IOS"
          } application version.</p>
        <p>It's easier to use in a mobile device. <br /> Don't worry, it has the same functions with the web version.</p>`,
        iconHtml: `<img src=${MobileVersionImage} alt="ipad" />`,
        customClass: {
          htmlContainer: "swal-custom-text",
          icon: "swal-custom-icon",
        },
        allowOutsideClick: false,
        // toast: true,
        // position: "bottom-start",
        background: "#f0eef7",
        confirmButtonText: `Download app on ${os?.name === "Android" ? "GooglePlay" : "AppStore"
          }`,
        confirmButtonColor: "#55428a",
        preConfirm: () => {
          window.open(
            os?.name === "Android"
              ? "https://play.google.com/store/apps/details?id=com.satelliteteams.employee&pcampaignid=web_share"
              : "https://apps.apple.com/us/app/satellite-teams-employee/id1665633591",
            "_blank"
          );
          return false; // Prevent confirmed
        },
        preDeny: () => {
          window.open(
            os?.name === "Android"
              ? "https://play.google.com/store/apps/details?id=com.satelliteteams.employee&pcampaignid=web_share"
              : "https://apps.apple.com/us/app/satellite-teams-employee/id1665633591",
            "_blank"
          );
          return false; // Prevent denied
        },
      }).then(() => {
        window.open(
          os?.name === "Android"
            ? "https://play.google.com/store/apps/details?id=com.satelliteteams.hiring.staging"
            : "https://apps.apple.com/app/satellite-jobs/id1612191019",
          "_blank"
        );
      });
    }

    //     if (os?.name === "Android") {
    //       Swal.fire({
    //         html: `<p>You're currently using an Android device.</p>
    //         <p>For better user experience, kindly download our app on the PlayStore.</p>
    //         <a href="https://play.google.com/store/apps/details?id=com.satelliteteams.hiring.staging" target="_blank"
    // >        <img src=${getOnPlayStoreImage} width="120" height="36" alt="download playstore"/></a>`,
    //         iconHtml: `<img src=${androidImage} width="60" height="60" alt="ipad" />`,
    //         customClass: {
    //           htmlContainer: "swal-custom-text",
    //           icon: "swal-custom-icon",
    //         },
    //         toast: true,
    //         position: "bottom-start",
    //         showConfirmButton: false,
    //         background: "#f0eef7",
    //         timer: 500,
    //         didOpen: () => {
    //           Swal.stopTimer();
    //         },
    //       });
    //     }
  }, [browser, os]);

  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={2500}>
      <DataContextProvider>
        <div className="App">
          {/* <Suspense
        fallback={
          <PageLoader
            color1={"#463672"}
            color2={"#fff"}
            containerHeight="100vh"
          />
        }
      > */}
          <Routes>
            <Route path="/" element={<ProtectedRoutes />}>
              {menu?.map((mainLabel, i) =>
                mainLabel?.menu?.map((menu, im) => {
                  if (menu.sub_menu && menu.sub_menu.length > 0) {
                    return (
                      // <Route path=":menuName" key={im}>
                      <Route path={replaceAndSpace(menu.label)} key={im}>
                        {menu.sub_menu.map((sub, is) => {
                          if (sub.sub_level && sub.sub_level.length > 0) {
                            return (
                              <Route path={replaceAndSpace(sub.label)} key={is}>
                                {sub.sub_level.map((subSub, iss) => {
                                  if (
                                    subSub.sub_level_2 &&
                                    subSub.sub_level_2.length > 0
                                  ) {
                                    return (
                                      <Route
                                        path={replaceAndSpace(subSub.label)}
                                        key={iss}
                                      >
                                        {subSub.sub_level_2.map(
                                          (subSubSub, isss) => (
                                            <Route
                                              path={replaceAndSpace(
                                                subSubSub.label
                                              )}
                                              key={isss}
                                              element={setMenuComponent(
                                                `${replaceAndSpace(
                                                  menu.label
                                                )}/${replaceAndSpace(
                                                  sub.label
                                                )}/${replaceAndSpace(
                                                  subSub.label
                                                )}/${replaceAndSpace(
                                                  subSubSub.label
                                                )}`,
                                                subSubSub.permission
                                              )}
                                            />
                                          )
                                        )}
                                      </Route>
                                    );
                                  }

                                  return (
                                    <Route
                                      path={replaceAndSpace(subSub.label)}
                                      // path=":subName"
                                      key={iss}
                                      element={setMenuComponent(
                                        `${replaceAndSpace(
                                          menu.label
                                        )}/${replaceAndSpace(
                                          sub.label
                                        )}/${replaceAndSpace(subSub.label)}`,
                                        subSub.permission
                                      )}
                                    />
                                  );
                                })}
                              </Route>
                            );
                          }

                          return (
                            <Route
                              path={replaceAndSpace(sub.label)}
                              // path=":subName"
                              key={is}
                              element={setMenuComponent(
                                `${replaceAndSpace(menu.label)}/${replaceAndSpace(
                                  sub.label
                                )}`,
                                sub.permission
                              )}
                            />
                          );
                        })}
                      </Route>
                    );
                  }

                  return (
                    <Route
                      path={
                        menu?.label === "Dashboard"
                          ? "/"
                          : replaceAndSpace(menu?.label)
                      }
                      // path={menu.label === "Dashboard" ? "/" : ":menuName"}
                      key={im}
                      element={setMenuComponent(
                        replaceAndSpace(menu?.label),
                        menu.permission
                      )}
                    />
                  );
                })
              )}
              <Route path="/" element={<Dashboard />} />
              <Route path="/profile/" element={<Profile />} />
            </Route>
            <Route path="login" element={<PublicRoutes />}>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route path="*" element={<PublicRoutes notFound={true} />}>
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route
              path="forgot-password"
              element={<PublicRoutes hasBgName={false} />}
            >
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Route>
            <Route
              path="/reset-password"
              element={<PublicRoutes hasBgName={false} />}
            >
              <Route path="/reset-password/:token" element={<ResetPassword />} />
            </Route>
            <Route
              path="/change-password"
              element={<PublicRoutes hasBgName={false} />}
            >
              <Route
                path="/change-password"
                element={<ResetPassword updatePassword={true} />}
              />
            </Route>
          </Routes>
          {/* </Suspense> */}
        </div>
        <p
          style={{
            position: "fixed",
            bottom: 5,
            right: 13,
            fontSize: ".7rem",
            color: "#ccc",
          }}
        >
          {"v. " + process.env.REACT_APP_VERSION}
        </p>
      </DataContextProvider>
    </SnackbarProvider>
  );
}

export default App;