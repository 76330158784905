import React from "react";
import { usePagination, DOTS } from "../../utils/pagination";
import "./_CustomPagination.scss";

import { Stack, Typography } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export const CustomPagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div
      className="custom-pagination-main-container"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: 20,
      }}
    >
      <div>
        {/* <span className="gray-text">
          Showing{" "}
          <span style={{ fontWeight: 600, color: "#885AF8" }}>
            {currentPage === 1 ? currentPage : (currentPage - 1) * 10 + 1} -{" "}
            {currentPage === 1 ? pageSize : currentPage * pageSize}
          </span>{" "}
          of {totalCount}
        </span> */}
        <span className="gray-text">
          Showing{" "}
          <span style={{ fontWeight: 600, color: "#885AF8" }}>
            {currentPage === 1 ? currentPage : (currentPage - 1) * pageSize + 1}{" "}
            -{" "}
            {currentPage === 1
              ? lastPage === currentPage
                ? currentPage * pageSize - (currentPage * pageSize - totalCount)
                : pageSize
              : lastPage === currentPage
                ? currentPage * pageSize - (currentPage * pageSize - totalCount)
                : currentPage * pageSize}
          </span>{" "}
          of {totalCount}
        </span>
      </div>
      <div>
        <ul className="pagination-container">
          <li
            className={`pagination-item prev ${currentPage === 1 && "disabled"
              }`}
            onClick={onPrevious}
          >
            PREV
          </li>

          {paginationRange.map((pageNumber, key) => {
            if (pageNumber === DOTS) {
              return (
                <li key={key} className="pagination-item dots">
                  &#8230;
                </li>
              );
            }

            return (
              <li
                key={key}
                className={`pagination-item ${pageNumber === currentPage && "selected"
                  }`}
                onClick={() =>
                  pageNumber !== currentPage && onPageChange(pageNumber)
                }
              >
                {pageNumber}
              </li>
            );
          })}

          <li
            className={`pagination-item next ${(currentPage === lastPage || totalCount === 0) && "disabled"
              }`}
            onClick={onNext}
          >
            NEXT
          </li>
        </ul>
      </div>
    </div>
  );
};

export const CustomStylePagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between" mt={2} spacing={2} alignItems="center">
      <div>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            color="#617488"
            fontWeight={500}
            padding={1.5}
            fontSize={"15px"}
            sx={{ backgroundColor: "#f2f5f7", borderRadius: "5px" }}
          >
            Showing
          </Typography>
          <Typography
            color="#593F8F"
            fontWeight={700}
            padding={1.5}
            fontSize={"15px"}
            sx={{ backgroundColor: "#f2f5f7", borderRadius: "5px" }}
          >
            {currentPage === 1 ? currentPage : (currentPage - 1) * pageSize + 1}{" "}
            -{" "}
            {currentPage === 1
              ? lastPage === currentPage
                ? currentPage * pageSize - (currentPage * pageSize - totalCount)
                : pageSize
              : lastPage === currentPage
                ? currentPage * pageSize - (currentPage * pageSize - totalCount)
                : currentPage * pageSize}
          </Typography>
          <Typography
            color="#617488"
            fontWeight={500}
            padding={1.5}
            fontSize={"15px"}
            sx={{ backgroundColor: "#f2f5f7", borderRadius: "5px" }}
          >
            of {totalCount}
          </Typography>
        </Stack>
      </div>
      <div>
        <Stack direction="row" spacing={1} sx={{ cursor: "pointer" }}>
          <Typography
            color="#FFFFFF"
            fontWeight={500}
            padding={1.5}
            fontSize={"15px"}
            sx={{
              backgroundColor: "#593f8f",
              borderRadius: "5px",
              opacity: currentPage === 1 && "0.4",
            }}
            onClick={currentPage !== 1 ? onPrevious : undefined}
          >
            Previous
          </Typography>

          <Stack direction="row" spacing={1}>
            {paginationRange.map((pageNumber, key) => {
              if (pageNumber === DOTS) {
                return (
                  <Typography
                    key={key}
                    color="#FFFFFF"
                    fontWeight={500}
                    padding={1.5}
                    fontSize={"15px"}
                    sx={{ backgroundColor: "#d3cae8", borderRadius: "5px" }}
                  >
                    &#8230;
                  </Typography>
                );
              }

              return (
                <Typography
                  key={key}
                  color="#FFFFFF"
                  fontWeight={500}
                  paddingY={1.5}
                  paddingX={2}
                  fontSize={"15px"}
                  sx={{
                    backgroundColor:
                      pageNumber === currentPage ? "#593f8f" : "#d3cae8",
                    borderRadius: "5px",
                  }}
                  onClick={() =>
                    pageNumber !== currentPage ? onPageChange(pageNumber) : undefined
                  }
                >
                  {pageNumber}
                </Typography>
              );
            })}
          </Stack>
          <Typography
            color="#FFFFFF"
            fontWeight={500}
            padding={1.5}
            fontSize={"15px"}
            sx={{
              backgroundColor: "#593f8f",
              borderRadius: "5px",
              opacity: (currentPage === lastPage || totalCount === 0) && "0.4",
            }}
            onClick={(currentPage !== lastPage && totalCount !== 0) ? onNext : undefined}
          >
            Next
          </Typography>
        </Stack>
      </div>
    </Stack>
  );
};

export const NoTotalPagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <Stack direction={{ xs: "column", sm: "row" }} justifyContent="center" mt={2} spacing={2} alignItems="center">
      <div>
        <Stack direction="row" spacing={1} sx={{ cursor: "pointer" }}>
          <Typography
            color="#FFFFFF"
            fontWeight={500}
            padding={1.5}
            fontSize={"15px"}
            sx={{
              backgroundColor: "#593f8f",
              borderRadius: "5px",
              opacity: currentPage === 1 && "0.4",
            }}
            onClick={currentPage !== 1 ? onPrevious : undefined}
          >
            &lt;
          </Typography>

          <Stack direction="row" spacing={1}>
            {paginationRange.map((pageNumber, key) => {
              if (pageNumber === DOTS) {
                return (
                  <Typography
                    key={key}
                    color="#FFFFFF"
                    fontWeight={500}
                    padding={1.5}
                    fontSize={"15px"}
                    sx={{ backgroundColor: "#d3cae8", borderRadius: "5px" }}
                  >
                    &#8230;
                  </Typography>
                );
              }

              return (
                <Typography
                  key={key}
                  color="#FFFFFF"
                  fontWeight={500}
                  paddingY={1.5}
                  paddingX={2}
                  fontSize={"15px"}
                  sx={{
                    backgroundColor:
                      pageNumber === currentPage ? "#593f8f" : "#d3cae8",
                    borderRadius: "5px",
                  }}
                  onClick={() =>
                    pageNumber !== currentPage ? onPageChange(pageNumber) : undefined
                  }
                >
                  {pageNumber}
                </Typography>
              );
            })}
          </Stack>
          <Typography
            color="#FFFFFF"
            fontWeight={500}
            padding={1.5}
            fontSize={"15px"}
            sx={{
              backgroundColor: "#593f8f",
              borderRadius: "5px",
              opacity: (currentPage === lastPage || totalCount === 0) && "0.4",
            }}
            onClick={(currentPage !== lastPage && totalCount !== 0) ? onNext : undefined}
          >
            &gt;
          </Typography>
        </Stack>
      </div>
    </Stack>
  );
};