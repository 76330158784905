import React from "react";
import { Button, styled } from "@mui/material";

const ColoredButton = styled(Button)(
  ({ theme, colortype, colorbg, colorhover }) => ({
    background: theme.palette[colortype][colorbg],
    textTransform: "none",
    "&:hover": {
      background: theme.palette[colortype][colorhover],
    },
  })
);

const OutlinedButton = styled(Button)(
  ({ theme, colortype, colorbg, colorhover }) => ({
    background: theme.palette[colortype][colorbg],
    border: `2px solid ${theme.palette[colortype][colorhover]}`,
    color: theme.palette[colortype][colorhover],
    textTransform: "none",
    "&:hover": {
      background: theme.palette[colortype][colorhover],
      border: `2px solid ${theme.palette[colortype][colorhover]}`,
      color: theme.palette.common.white,
      "& .MuiButton-startIcon svg": {
        color: theme.palette.common.white,
      },
    },
  })
);

const TextButtonStyled = styled(Button)(
  ({ theme, colortype, colortexthover, colortext }) => ({
    // textTransform: "none",
    fontWeight: 500,
    color: theme.palette[colortype][colortext],
    textTransform: "none",
    "&:hover": {
      color: theme.palette[colortype][colortexthover],
      background: "none",
    },
  })
);

const CustomButton = (props) => {
  const {
    type = "button",
    variant = "contained",
    text,
    disabled = false,
    size = "medium",
    startIcon = null,
    endIcon = null,
    colortype = "brandColor",
    colorbg = 500,
    colorhover = 700,
    fullWidth = false,
    onClick,
    style,
  } = props;

  return variant === "outlined" ? (
    <OutlinedButton
      type={type}
      variant={variant}
      disabled={disabled}
      size={size}
      startIcon={startIcon}
      colortype={colortype}
      colorbg={colorbg}
      colorhover={colorhover}
      fullWidth={fullWidth}
      // onClick={onClick}
      // style={style && style}
      {...props}
    >
      {text}
    </OutlinedButton>
  ) : (
    <ColoredButton
      type={type}
      variant={variant}
      disabled={disabled}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      colortype={colortype}
      colorbg={colorbg}
      colorhover={colorhover}
      fullWidth={fullWidth}
      // onClick={onClick}
      // style={style && style}
      {...props}
    >
      {text}
    </ColoredButton>
  );
};

export const TextButton = (props) => {
  const {
    colortype = "brandColor",
    colortext = 600,
    colortexthover = 400,
    text,
    size = "medium",
  } = props;

  return (
    <TextButtonStyled
      colortype={colortype}
      colortext={colortext}
      colortexthover={colortexthover}
      size={size}
      {...props}
    >
      {text}
    </TextButtonStyled>
  );
};

export default CustomButton;
