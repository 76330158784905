import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmModal = ({
  open = false,
  titleIcon = null,
  titleText = "",
  confirmText = "",
  isTwoActions = false,
  isOneAction = false,
  isLoading = false,
  confirmButton = null,
  cancelButton = null,
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      sx={(theme) => ({
        "& .MuiDialog-paper": {
          width: 500,
          borderRadius: 2,
          padding: 0,
        },
      })}
    >
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: theme.palette.brandColor[500],
        })}
      >
        <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
          {titleIcon && titleIcon}
          {titleText && (
            <Typography
              variant="body1"
              sx={(theme) => ({
                fontSize: ".9rem",
                color: theme.palette.common.white,
              })}
            >
              {titleText}
            </Typography>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent>
        {confirmText && (
          <Box
            sx={{
              paddingTop: 2,
              paddingBottom: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                fontSize: ".9rem",
              }}
            >
              {confirmText}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          paddingBottom: 2,
        }}
      >
        <>
          {isTwoActions && (
            <>
              {cancelButton}
              {confirmButton}
            </>
          )}
          {isOneAction && confirmButton}
        </>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
