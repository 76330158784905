export const getUser = () => {
  const userStr = sessionStorage.getItem("user");

  if (userStr) return JSON.parse(userStr);
  else return null;
};
export const getID = () => {
  return sessionStorage.getItem("userID");
};
export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};

export const setUserSession = (token, user, id) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
  sessionStorage.setItem("userID", id);
};

export const sessionCount = (count) => {
  sessionStorage.setItem("count", "1");
};

export const getCount = () => {
  return sessionStorage.getItem("count");
};

export const removeUserSession = () => {
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("count");
  sessionStorage.removeItem("userID");
};

export const saveToLocalStorage = (credentials) => {
  // const yourCredentials = localStorage.getItem("yourCredentials");
  localStorage.setItem("yourCredentials", JSON.stringify(credentials));
};

export const saveToSessionStorage = (credentials) => {
  // const yourCredentials = sessionStorage.getItem("yourCredentials");
  sessionStorage.setItem("yourCredentials", JSON.stringify(credentials));
};

export const getUserData = () => {
  // return JSON.parse(sessionStorage.getItem("yourCredentials"));
  const userStr = localStorage.getItem("yourCredentials");
  // const userStr = sessionStorage.getItem("yourCredentials");

  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const getLocalStorageData = () => {
  if (localStorage.getItem("yourCredentials")) {
    return JSON.parse(localStorage.getItem("yourCredentials"));
  }

  return false;
};

export const updateLocalStorage = (next_link) => {
  if (localStorage.getItem("yourCredentials")) {
    const userData = {
      ...JSON.parse(localStorage.getItem("yourCredentials")),
      next_link,
    };

    sessionStorage.setItem("yourCredentials", JSON.stringify(userData));
    localStorage.setItem("yourCredentials", JSON.stringify(userData));
  }
};

export const clearSessionStorage = () => {
  sessionStorage.removeItem("yourCredentials");
  localStorage.removeItem("yourCredentials");
};

export const setInitialRole = (role, medium, source) => {
  localStorage.setItem("initial-role", role);
  localStorage.setItem("initial-medium", medium);
  localStorage.setItem("initial-source", source);
};

export const getInitialRole = () => {
  if (localStorage.getItem("initial-role")) {
    return localStorage.getItem("initial-role");
  }
};

export const clearInitialRole = () => {
  localStorage.removeItem("initial-role");
  localStorage.removeItem("initial-medium");
  localStorage.removeItem("initial-source");
};

export const setApplicantTokenFromAgent = (token) => {
  localStorage.setItem("applicant-token-from-agent", token);
};

export const getApplicantTokenFromAgent = () => {
  if (localStorage.getItem("applicant-token-from-agent")) {
    return localStorage.getItem("applicant-token-from-agent");
  }
};

export const clearApplicantTokenFromAgent = () => {
  localStorage.removeItem("applicant-token-from-agent");
};

export const setApplicantFromAgent = (credentials) => {
  localStorage.setItem("applicant-from-agent", JSON.stringify(credentials));
};

export const getApplicantFromAgent = () => {
  if (localStorage.getItem("applicant-from-agent")) {
    return JSON.parse(localStorage.getItem("applicant-from-agent"));
  }
};

export const clearApplicantFromAgent = () => {
  localStorage.removeItem("applicant-from-agent");
};

export const setUserStatus = (status) => {
  localStorage.setItem("sat-user-status", status);
};

export const getUserStatus = () => {
  if (localStorage.getItem("sat-user-status")) {
    return localStorage.getItem("sat-user-status");
  }
};

export const removeUserStatus = () => {
  if (localStorage.getItem("sat-user-status"))
    localStorage.removeItem("sat-user-status");
};

export const replaceValueSpaceHyphen = (value) => {
  return value.replaceAll(" ", "-");
};

export const setRedirectToSched = (link) => {
  sessionStorage.setItem("sat-redirect", link);
};

export const removeRedirectSched = () => {
  if (sessionStorage.getItem("sat-redirect")) {
    sessionStorage.removeItem("sat-redirect");
  }
};

export const getRedirectSched = () => {
  if (sessionStorage.getItem("sat-redirect")) {
    return sessionStorage.getItem("sat-redirect");
  }

  return null;
};

export const showLastThreeNumbersOnly = (number) => {
  let hideNum = [];

  for (let i = 0; i < number.length; i++) {
    if (i < number.length - 3) {
      hideNum.push("*");
    } else {
      hideNum.push(number[i]);
    }
  }

  return hideNum.join("");
};

export const showFewCharactersOnly = (string) => {
  let hideChar = [];

  for (let i = 0; i < string.length; i++) {
    if (i < 3) {
      hideChar.push(string[i]);
    } else if (i > string.length - 4) {
      hideChar.push(string[i]);
    } else {
      hideChar.push("*");
    }
  }

  return hideChar.join("");
};

export const replaceAndSpace = (value) =>
  value
    .replaceAll(" And ", "-")
    .replaceAll(" and ", "-")
    .replaceAll(" & ", "-")
    .replaceAll(" / ", "-")
    .replaceAll("/", "-")
    .replaceAll(" ", "-")
    .toLowerCase();

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};