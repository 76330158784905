import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { useAuth } from "../../utils/useAuth";
import MenuLayout from "../layout/MenuLayout";
import ConfirmModal from "../_Reusables/ConfirmModal";
import CustomButton from "../_Reusables/CustomButton";
import { removeFromLocalStorage } from "../../utils/localStorage";

import LogoutIcon from '@mui/icons-material/Logout';

const ProtectedRoutes = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const [inactivityModal, setInactivityModal] = useState(false);

  // || user.new === 1

  const clearLocalStorageAndLogout = () => {
    setInactivityModal(false);
    removeFromLocalStorage("yourCredentials");
    removeFromLocalStorage("st-admin-other-token");
    sessionStorage.clear();
    navigate("/login");
  };

  // Set up a timer to clear local storage after 30 minutes of inactivity
  useEffect(() => {
    let inactivityTimer;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => setInactivityModal(true), 30 * 60 * 1000); // 30 minutes in milliseconds
    };

    const handleUserActivity = () => {
      resetInactivityTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);

    // Initialize the inactivity timer
    resetInactivityTimer();

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      clearTimeout(inactivityTimer);
    };
  }, []);

  useEffect(() => {
    if (inactivityModal === true) {
      const handleBeforeUnload = () => {
        clearLocalStorageAndLogout();
      }

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [inactivityModal]);

  if (!user || user.new === 1) return <Navigate to="/login" />;

  return (
    <MenuLayout>
      <Outlet />
      <ConfirmModal
        open={inactivityModal}
        isOneAction
        titleIcon={
          <LogoutIcon
            sx={(theme) => ({
              color: theme.palette.common.white,
              fontSize: 100,
            })}
          />
        }
        titleText="Session Expired"
        confirmText="You are logged out due to inactivity."
        confirmButton={
          <CustomButton
            colortype="brandColor"
            text="Confirm"
            onClick={clearLocalStorageAndLogout}
            sx={{
              width: "150px",
            }}
          />
        }
      />
    </MenuLayout>
  );
};

export default ProtectedRoutes;
