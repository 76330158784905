import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as API from "../../api";
import { setToLocalStorage } from "../../utils/localStorage";

const menu = [
  {
    "id": 1,
    "label": "Main Menu",
    "code_snippet": "Main Menu",
    "menu": [
      {
        "id": 1,
        "label": "Dashboard",
        "code_snippet": "Dashboard",
        "permission": [
          "Read",
          "List",
          "Generate"
        ],
        "sub_menu": []
      }
    ]
  },
  {
    "id": 2,
    "label": "Account",
    "code_snippet": "Account",
    "menu": [
      {
        "id": 2,
        "label": "Profile",
        "code_snippet": "Profile",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": []
      },
      {
        "id": 3,
        "label": "My Documents",
        "code_snippet": "My Documents",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": []
      },
      // {
      //   "id": 4,
      //   "label": "My Schedule",
      //   "code_snippet": "My Schedule",
      //   "permission": [
      //     "Create",
      //     "Read",
      //     "Update",
      //     "Delete",
      //     "List",
      //     "Generate"
      //   ],
      //   "sub_menu": []
      // },
      {
        "id": 5,
        "label": "Attendance Summary",
        "code_snippet": "Attendance Summary",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": []
      },
      {
        "id": 6,
        "label": "File A Request",
        "code_snippet": "File A Request",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": [
          {
            "id": 1,
            "label": "Leave",
            "code_snippet": "Leave",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 2,
            "label": "Certificate of Attendance",
            "code_snippet": "Certificate of Attendance",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 3,
            "label": "Schedule Adjustment",
            "code_snippet": "Schedule Adjustment",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 4,
            "label": "Overtime",
            "code_snippet": "Overtime",
            "permission": [],
            "sub_level": [],
          },
          // {
          //   "id": 5,
          //   "label": "Undertime",
          //   "code_snippet": "Undertime",
          //   "permission": [],
          //   "sub_level": [],
          // },
          {
            "id": 6,
            "label": "Official Business",
            "code_snippet": "Official Business",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 7,
            "label": "Asset",
            "code_snippet": "Asset",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 20,
            "label": "Reimbursement",
            "code_snippet": "Reimbursement",
            "permission": [],
            "sub_level": [],
          }
        ],
      },
      {
        "id": 7,
        "label": "My Requests",
        "code_snippet": "My Requests",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": [
          {
            "id": 8,
            "label": "My Leaves",
            "code_snippet": "My Leaves",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 9,
            "label": "My Certificate of Attendance",
            "code_snippet": "My Certificate of Attendance",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 10,
            "label": "My Schedule Adjustments",
            "code_snippet": "My Schedule Adjustments",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 11,
            "label": "My Overtimes",
            "code_snippet": "My Overtimes",
            "permission": [],
            "sub_level": [],
          },
          // {
          //   "id": 12,
          //   "label": "My Undertimes",
          //   "code_snippet": "My Undertimes",
          //   "permission": [],
          //   "sub_level": [],
          // },
          {
            "id": 13,
            "label": "My Official Businesses",
            "code_snippet": "My Official Businesses",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 14,
            "label": "My Assets",
            "code_snippet": "My Assets",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 15,
            "label": "My Reimbursements",
            "code_snippet": "My Reimbursements",
            "permission": [],
            "sub_level": [],
          }
        ],
      },
    ]
  },
  // {
  //   "id": 3,
  //   "label": "LMS",
  //   "code_snippet": "LMS",
  //   "menu": [
  //     {
  //       "id": 8,
  //       "label": "LMS",
  //       "code_snippet": "LMS",
  //       "permission": [
  //         "Create",
  //         "Read",
  //         "Update",
  //         "Delete",
  //         "List",
  //         "Generate"
  //       ],
  //       "sub_menu": []
  //     },
  //   ],
  // },
  {
    "id": 4,
    "label": "Referrals",
    "code_snippet": "Referrals",
    "menu": [
      {
        "id": 9,
        "label": "Referrals",
        "code_snippet": "Referrals",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": []
      },
    ],
  },
  // {
  //   "id": 5,
  //   "label": "Payroll",
  //   "code_snippet": "Payroll",
  //   "menu": [
  //     {
  //       "id": 10,
  //       "label": "My Payroll Summary",
  //       "code_snippet": "My Payroll Summary",
  //       "permission": [
  //         "Create",
  //         "Read",
  //         "Update",
  //         "Delete",
  //         "List",
  //         "Generate"
  //       ],
  //       "sub_menu": []
  //     },
  //   ],
  // },
]

const menu_offboarding = [
  {
    "id": 1,
    "label": "Main Menu",
    "code_snippet": "Main Menu",
    "menu": [
      {
        "id": 1,
        "label": "Dashboard",
        "code_snippet": "Dashboard",
        "permission": [
          "Read",
          "List",
          "Generate"
        ],
        "sub_menu": []
      }
    ]
  },
  {
    "id": 2,
    "label": "Account",
    "code_snippet": "Account",
    "menu": [
      {
        "id": 5,
        "label": "Attendance Summary",
        "code_snippet": "Attendance Summary",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": []
      },
      {
        "id": 6,
        "label": "File A Request",
        "code_snippet": "File A Request",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": [
          {
            "id": 1,
            "label": "Leave",
            "code_snippet": "Leave",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 2,
            "label": "Certificate of Attendance",
            "code_snippet": "Certificate of Attendance",
            "permission": [],
            "sub_level": [],
          },
        ],
      },
      {
        "id": 7,
        "label": "My Requests",
        "code_snippet": "My Requests",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": [
          {
            "id": 8,
            "label": "My Leaves",
            "code_snippet": "My Leaves",
            "permission": [],
            "sub_level": [],
          },
          {
            "id": 9,
            "label": "My Certificate of Attendance",
            "code_snippet": "My Certificate of Attendance",
            "permission": [],
            "sub_level": [],
          },
        ],
      },
    ]
  },
  {
    "id": 4,
    "label": "Referrals",
    "code_snippet": "Referrals",
    "menu": [
      {
        "id": 9,
        "label": "Referrals",
        "code_snippet": "Referrals",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": []
      },
    ],
  },
  {
    "id": 5,
    "label": "Offboarding",
    "code_snippet": "Referrals",
    "menu": [
      {
        "id": 10,
        "label": "Offboarding Clearance",
        "code_snippet": "Offboarding Clearance",
        "permission": [
          "Create",
          "Read",
          "Update",
          "Delete",
          "List",
          "Generate"
        ],
        "sub_menu": []
      },
    ],
  },
  // {
  //   "id": 5,
  //   "label": "Payroll",
  //   "code_snippet": "Payroll",
  //   "menu": [
  //     {
  //       "id": 10,
  //       "label": "My Payroll Summary",
  //       "code_snippet": "My Payroll Summary",
  //       "permission": [
  //         "Create",
  //         "Read",
  //         "Update",
  //         "Delete",
  //         "List",
  //         "Generate"
  //       ],
  //       "sub_menu": []
  //     },
  //   ],
  // },
];

export const sign_in = createAsyncThunk(
  "auth/sign_in",
  async (props, { rejectWithValue }) => {
    try {
      const { data, status } = await API.loginUser(props);
      // const newToken = await API.generateNewToken({
      //   user_id: data.data.user_id,
      // });

      // console.log(data);

      // const modifiedMenu = data.data.menus.map((main) => {
      //   if (!Array.isArray(main.menu)) {
      //     return { ...main, menu: [main.menu] };
      //   }

      //   if (main.menu[0][0] !== undefined) {
      //     const menu = [];

      //     main.menu.forEach((item) => {
      //       item.forEach((item2) => {
      //         menu.push(item2);
      //       });
      //     });
      //     return { ...main, menu: menu };
      //   }

      //   return main;
      // });

      const actualData = {
        ...data.data,
        // menus: modifiedMenu,
        // email: newToken.data.data?.email,
        // location: newToken.data.data?.location,
      };

      const menus = data.data?.for_offboarding === 0 ?  menu : menu_offboarding;

      setToLocalStorage("menu", JSON.stringify(menus));
      setToLocalStorage("off_boarding", JSON.stringify(data.data?.for_offboarding));
      // setToLocalStorage("st-admin-other-token", newToken.data.data.token);

      return { data: actualData, message: data?.message, status };
    } catch (err) {
      console.log(err);
      const { status } = err.response;
      const { data, message } = err.response.data;
      return rejectWithValue({ data, message, status });
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    status: "idle",
    error: null,
    user: null,
  },
  extraReducers: {
    [sign_in.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
      state.error = null;
    },
    [sign_in.fulfilled]: (state, action) => {
      state.user = { ...action.payload.data, menu: action.payload.data.for_offboarding === 0 ? menu : menu_offboarding };
      state.status = "idle";
      state.loading = false;
    },
    [sign_in.rejected]: (state, action) => {
      state.error = { ...action.payload };
      state.status = "failed";
      state.loading = false;
    },
  },
});

export default authSlice.reducer;
