export const getUser = () => {
  const user = localStorage.getItem("yourCredentials");

  if (user) return JSON.parse(user);

  return null;
};

export const getMenu = () => {
  const menu = localStorage.getItem("menu");

  if (menu) return JSON.parse(menu);

  return null;
};

export const getOffboarding = () => {
  const offboarding = localStorage.getItem("off_boarding");

  if (offboarding) return JSON.parse(offboarding);

  return null;
};

export const otherToken = () => {
  const token = localStorage.getItem("st-admin-other-token");

  if (token) return token;

  return null;
};

export const removeFromLocalStorage = (key) => localStorage.removeItem(key);
export const removeFromSessionStorage = (key) => sessionStorage.removeItem(key);

export const setToLocalStorage = (key, value) => {
  if (key === "yourCredentials") {
    localStorage.setItem(key, JSON.stringify(value));
    return;
  }

  localStorage.setItem(key, value);
};

export const setToSessionStorage = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const getFromSessionStorage = (key) => {
  const val = sessionStorage.getItem(key);

  if (val) return val;

  return null;
};

export const getFromLocalStorage = (key) => {
  const val = localStorage.getItem(key);

  if (val) return val;

  return null;
};

export const getToken = () => {
  const token = localStorage.getItem("yourCredentials");

  if (token) return JSON.parse(token);

  return null;
};

export const getCookie = (name) => {
  var cookieArr = document.cookie.split(";");
  
  for(var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      
      if(name == cookiePair[0].trim()) {
          return decodeURIComponent(cookiePair[1]);
      }
  }
  return null;
}