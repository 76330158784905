import Swal from "sweetalert2";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { removeFromLocalStorage } from "./localStorage";
import lodashFlattenDeep from "lodash/flattenDeep";
import lodashIsEmpty from "lodash/isEmpty";

export const showError = (error, redirectPath) => {
  const text =
    error?.response?.data?.data?.error ||
    error?.response?.data?.message ||
    "Something went wrong. Please try again later.";

  if (error?.message !== "canceled") {
    if (text === "Unauthenticated." || text === "Invalid token.") {
      Swal.fire({
        title: "Session Expired",
        text: "Please log in again",
        icon: "info",
        confirmButtonText: "OK",
        confirmButtonColor: "#55428A",
      }).then(() => {
        window.location.href = "/login";
        removeFromLocalStorage("yourCredentials");
      });
    } else {
      if (typeof text === "object") {
        const objectValues = Object.values(text) || [];
        const joinedArray = lodashFlattenDeep(objectValues);

        Swal.fire({
          customClass: {
            container: "zIndex10000",
          },
          title: "",
          html: `${
            lodashIsEmpty(joinedArray)
              ? ""
              : joinedArray.map((value) => `<div>${value}<div>`)
          }`,
          icon: "error",
          confirmButtonText: "CLOSE",
          confirmButtonColor: "#55428A",
        }).then(() =>
          redirectPath ? (window.location.href = redirectPath) : null
        );
      } else {
        Swal.fire({
          customClass: {
            container: "zIndex10000",
          },
          title: "",
          text,
          icon: "error",
          confirmButtonText: "CLOSE",
          confirmButtonColor: "#55428A",
        }).then(() =>
          redirectPath ? (window.location.href = redirectPath) : null
        );
      }
    }
  }
};

export const showErrorAlert = ({ error, title }) => {
  const description =
    error?.response?.data?.data?.error ||
    error?.response?.data?.message ||
    "Something went wrong. Please try again later.";

  return (
    <Alert severity="error" style={{ marginBottom: 15 }}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {description}
    </Alert>
  );
};

export const showInfo = (text, redirectPath, isHtml = false) => {
  isHtml
    ? Swal.fire({
        title: "",
        html: text,
        icon: "info",
        confirmButtonText: "OK",
        confirmButtonColor: "#55428A",
      }).then(() =>
        redirectPath ? (window.location.href = redirectPath) : null
      )
    : Swal.fire({
        title: "",
        text,
        icon: "info",
        confirmButtonText: "OK",
        confirmButtonColor: "#55428A",
      }).then(() =>
        redirectPath ? (window.location.href = redirectPath) : null
      );
};
