import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";

// local imports
import './index.css';
import App from './App';
import store from "./redux/store";
import "./index.css";
import "./scss/_variables.scss";
import "./scss/_resets.scss";
import "./scss/_globals.scss";
import { theme } from "./utils/theme";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
    <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          maxSnack={3}
          autoHideDuration={4000}
        >
      <BrowserRouter>
        <CssBaseline />
        <App />
      </BrowserRouter>
        </SnackbarProvider>
    </ThemeProvider>
  </Provider>

  // </React.StrictMode>
);
