import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import InputAdornment from "@mui/material/InputAdornment";
import { Controller, useFormState } from "react-hook-form";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const InputTextField = (props) => {
  const {
    type = "text",
    name,
    onChange,
    placeholder,
    size,
    label,
    startIcon = null,
    error,
    labelTop,
    defaultValue,
    value,
    disabled,
    multiline = false,
    className,
    rows = 0,
    autoComplete,
    bradius = "4px",
    style,
    readOnly = false,
    loading = false,
    ...restProps
  } = props;
  const [togglePassword, setTogglePassword] = useState(false);
  return (
    <div>
      {labelTop && <Typography color="brandColor.500" fontSize=".85rem" mb="5px">{labelTop}</Typography>}
      {loading ?
        <Skeleton variant="rectangular" height={multiline ? (21*rows) : 42} />
        :
        <TextField
          sx={{
            "& .MuiOutlinedInput-root": {
              height: multiline ? "auto" : 42,
              borderRadius: bradius,
              "&.Mui-disabled": {
                background: "rgb(240, 238, 247)",
              },
            },
          }}
          // sx={{
          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          //     borderColor: "brandColor.600",
          //     boxShadow: "0px 0px 5px rgba(123, 97, 255, 0.25)",
          //   },
          //   "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          //     {
          //       borderColor: "brandColor.600",
          //       borderWidth: "1px",
          //       boxShadow: "0px 0px 5px rgba(123, 97, 255, 0.25)",
          //     },
          // }}
          type={type !== "password" ? type : togglePassword ? "text" : type}
          name={name}
          disabled={disabled}
          error={error ? true : false}
          helperText={error && error}
          FormHelperTextProps={{ style: { marginLeft: "0" } }}
          onChange={onChange}
          placeholder={placeholder}
          size={size}
          label={label && label}
          fullWidth
          autoFocus={type == "email" ? true : false}
          defaultValue={defaultValue}
          value={value}
          className={className}
          InputProps={{
            startAdornment: startIcon && (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            ),
            endAdornment: type === "password" && (
              <InputAdornment
                position="end"
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => setTogglePassword((prev) => !prev)}
              >
                {togglePassword ? <VisibilityOutlinedIcon sx={{ color: "#AAA1C5"}} /> : <VisibilityOffOutlinedIcon sx={{ color: "#AAA1C5"}} />}
              </InputAdornment>
            ),
            readOnly: readOnly,
          }}
          multiline={multiline}
          rows={rows}
          autoComplete={autoComplete}
          style={style && style}
          onKeyPress={props.onKeyPress && props.onKeyPress}
          {...restProps}
        />
      }
    </div>
  );
};

export const ControlledInputTextField = React.forwardRef((props, ref) => {
  const {
    name,
    control,
    placeholder,
    size,
    label,
    startIcon = null,
    rules,
    type,
    labelTop,
    defaultValue,
    multiline = false,
    rows = 0,
    unControlledOnChange,
    customErrorMessage = null,
    ...restProps
  } = props;

  const formState = useFormState();

  return (
    <Controller
      name={name}
      ref={ref}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <InputTextField
          name={name}
          type={type}
          onChange={unControlledOnChange ? unControlledOnChange : onChange}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          size={size}
          label={label && label}
          startIcon={startIcon && startIcon}
          error={formState.errors[name]?.message || customErrorMessage || null}
          labelTop={labelTop && labelTop}
          defaultValue={defaultValue}
          multiline={multiline}
          rows={rows}
          {...restProps}
        />
      )}
    />
  );
});

export default InputTextField;
