import React from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import { Controller, useFormState } from "react-hook-form";
import { Typography } from "@mui/material";

export const Menu = (props) => {
  if (props.selectProps.inputValue.length === 0) return null;

  return (
    <>
      <components.Menu {...props} />
    </>
  );
};

export const SuggestiveSchool = (props) => (
  <div style={{ padding: "10px 20px" }}>
    <p
      style={{
        fontSize: ".8rem",
        fontWeight: "400",
        color: "#605f68",
        marginBottom: "10px",
      }}
    >
      <span style={{ color: "#55428a" }}>{props.posInputVal}</span> is not on
      our Institute/University list.
    </p>
    <p
      style={{
        fontSize: ".8rem",
        fontWeight: "400",
        color: "#605f68",
      }}
    >
      <span
        style={{
          fontWeight: "700",
          color: "#55428a",
          cursor: "pointer",
        }}
        onClick={props.handleSetNoOptions}
      >
        CLICK HERE
      </span>{" "}
      if this is your preferred Institute/University.
    </p>
  </div>
);

export const JobRoles = (props) => (
  <div style={{ padding: "10px 20px" }}>
    <p
      style={{
        fontSize: ".8rem",
        fontWeight: "400",
        color: "#605f68",
        marginBottom: "10px",
      }}
    >
      <span style={{ color: "#55428a" }}>{props.posInputVal}</span> is not on
      our role list.
    </p>
    <p
      style={{
        fontSize: ".8rem",
        fontWeight: "400",
        color: "#605f68",
      }}
    >
      <span
        style={{
          fontWeight: "700",
          color: "#55428a",
          cursor: "pointer",
        }}
        onClick={props.handleSetNoOptions}
      >
        CLICK HERE
      </span>{" "}
      if this is your preferred role.
    </p>
  </div>
);

const CustomReactSelect = React.forwardRef(
  (
    {
      label,
      placeholder,
      options,
      optLabelKey,
      optValueKey,
      error,
      eventOnChange,
      isClearable,
      isSearchable,
      isAsync,
      value,
      loadOptions,
      defaultValue,
      isDisabled,
      InputChange,
      handleSetNoOptions,
      isMenuOpen,
      posInputVal,
      category,
      defaultOptions = [],
      selectedOthers = false,
      hasDropDown = true,
    },
    ref
  ) => {
    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: 42,
        background: state.isDisabled ? "#F7F5F2" : "#fff",
        border: state.isFocused
          ? "1px solid #55428a"
          : `1px solid ${error ? "#d32f2f" : "#cfd5de"}`,
        width: "100%",
        fontSize: "0.85rem",
        fontWeight: "500",
        borderRadius: "5px",
        boxShadow: state.isFocused
          ? "0px 0px 5px rgba(123, 97, 255, 0.25)"
          : error
          ? "0px 0px 5px #fde0dd"
          : "none",
        "&:hover": {
          border: "1px solid #55428a",
          boxShadow: "0px 0px 5px rgba(123, 97, 255, 0.25)",
        },
      }),
      valueContainer: (base, state) => ({
        ...base,
        padding: "10px 15px",
      }),
      placeholder: (base, state) => ({
        ...base,
        margin: 0,
        color: "#cfd5de",
        fontWeight: "400",
        fontSize: ".8rem",
      }),
      input: (base, state) => ({
        ...base,
        margin: 0,
        padding: 0,
        color: "#7964b5",
      }),
      indicatorSeparator: (base, state) => ({
        ...base,
        background: "transparent",
      }),
      singleValue: (base, state) => ({
        ...base,
        color: "#7964b5",
        margin: 0,
      }),
      menu: (base, state) => ({
        ...base,
        zIndex: 2,
        display: selectedOthers ? "none" : "block",
      }),
      menuList: (base, state) => ({
        ...base,
        "&::-webkit-scrollbar": {
          width: "10px",
          height: "100%",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f0eef7",
          borderRadius: "50px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#a698cd",
          borderRadius: "50px",
          border: "2px solid #f0eef7",
        },
      }),
      option: (base, state) => ({
        ...base,
        fontSize: "0.85rem",
        fontWeight: "500",
        color: "#000",
        "&:hover": {
          background: "#f0eef7",
        },
        "&:active": {
          background: "#f0eef7",
        },
      }),
    };

    const labelStyles = {
      fontSize: ".85rem",
      fontWeight: "400",
      color: "#93929b",
      display: "block",
      marginBottom: "5px",
    };

    const errorStyles = {
      fontSize: ".7rem",
      fontWeight: "400",
      textAlign: "left",
      marginTop: "5px",
      color: "#d32f2f",
    };

    const valueStyle = {
      color: `"#7964b5"}`,
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      boxSizing: "border-box",
      margin: "0",
      padding: "10px 15px",
      fontSize: "0.85rem",
      fontWeight: "500",
      position: "absolute",
      top: "0",
      left: "0",
    };

    return (
      <div style={{ position: "relative" }}>
        {value === "" ||
          value === null ||
          (isNaN(value) && <p style={valueStyle}>{value}</p>)}
        {label && <label style={labelStyles}>{label}</label>}
        {isAsync ? (
          <AsyncSelect
            ref={ref}
            cacheOptions
            defaultOptions={defaultOptions}
            isClearable={isClearable}
            backspaceRemovesValue={true}
            isSearchable={isSearchable !== undefined ? isSearchable : true}
            getOptionLabel={(e) => e[optLabelKey]}
            getOptionValue={(e) => e[optValueKey]}
            menuIsOpen={isMenuOpen && isMenuOpen}
            styles={customStyles}
            placeholder={placeholder}
            loadOptions={loadOptions}
            onChange={eventOnChange}
            components={{
              Menu: (props) => {
                if (
                  props.selectProps.inputValue.length === 0 &&
                  category !== "job role"
                )
                  return null;

                return (
                  <>
                    <components.Menu {...props} />
                  </>
                );
              },
              DropdownIndicator: (props) =>
                category !== "job role" ? null : (
                  <components.DropdownIndicator {...props} />
                ),
              NoOptionsMessage: (props) => {
                if (category === "job role") {
                  if (props.selectProps.inputValue.length === 0) {
                    return <components.NoOptionsMessage {...props} />;
                  }

                  if (posInputVal.length === 0) return null;

                  return (
                    <JobRoles
                      posInputVal={posInputVal}
                      handleSetNoOptions={handleSetNoOptions}
                      {...props}
                    />
                  );
                }

                if (category === "suggestive schools") {
                  return (
                    <SuggestiveSchool
                      posInputVal={posInputVal}
                      handleSetNoOptions={handleSetNoOptions}
                      {...props}
                    />
                  );
                }

                return <components.NoOptionsMessage {...props} />;
              },
            }}
            value={defaultValue}
            onInputChange={InputChange && InputChange}
            isDisabled={isDisabled && isDisabled}
          />
        ) : (
          <Select
            ref={ref}
            isClearable={isClearable !== undefined ? isClearable : true}
            isSearchable={isSearchable !== undefined ? isSearchable : true}
            isDisabled={isDisabled}
            options={options}
            getOptionLabel={(option) => option[optLabelKey]}
            getOptionValue={(option) => option[optValueKey]}
            onChange={eventOnChange}
            styles={customStyles}
            placeholder={placeholder}
            value={defaultValue}
            components={{
              DropdownIndicator: (props) =>
                hasDropDown ? (
                  <components.DropdownIndicator {...props} />
                ) : null,
            }}
          />
        )}
        {error && <p style={errorStyles}>{error}</p>}
      </div>
    );
  }
);

export const ControlledCustomReactSelect = ({
  name,
  control,
  isClearable = false,
  isSearchable = true,
  isDisabled = false,
  isLoading = false,
  options = [],
  optLabelKey,
  optValueKey,
  placeholder,
  error,
  selectedOthers,
  unControlledOnChange,
  defaultValue,
  rules,
  label,
  menuMaxHeight,
  menuPlacement = "auto",
  customErrorMessage = null,
}) => {
  const formState = useFormState();

  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 42,
      background: state.isDisabled ? "rgb(240, 238, 247)" : "#fff",
      border: state.isFocused
        ? "1px solid #55428a"
        : `1px solid ${customErrorMessage || formState.errors[name]?.message ? "#d32f2f" : "#cfd5de"
          }`,
      width: "100%",
      fontSize: "0.85rem",
      fontWeight: "500",
      borderRadius: "5px",
      boxShadow: state.isFocused
        ? "0px 0px 5px rgba(123, 97, 255, 0.25)"
        : customErrorMessage || formState.errors[name]?.message
        ? "0px 0px 5px #fde0dd"
        : "none",
      "&:hover": {
        border: "1px solid #55428a",
        boxShadow: "0px 0px 5px rgba(123, 97, 255, 0.25)",
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: "10px 15px",
    }),
    placeholder: (base, state) => ({
      ...base,
      margin: 0,
      color: "#cfd5de",
      fontWeight: "400",
      fontSize: ".8rem",
    }),
    input: (base, state) => ({
      ...base,
      margin: 0,
      padding: 0,
      color: "#7964b5",
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      background: "transparent",
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "#7964b5",
      margin: 0,
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: 2,
      display: selectedOthers ? "none" : "block",
    }),
    menuList: (base, state) => ({
      ...base,
      maxHeight: menuMaxHeight && menuMaxHeight,
      "&::-webkit-scrollbar": {
        width: "10px",
        height: "100%",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f0eef7",
        borderRadius: "50px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#a698cd",
        borderRadius: "50px",
        border: "2px solid #f0eef7",
      },
    }),
    option: (base, state) => ({
      ...base,
      fontSize: "0.85rem",
      fontWeight: "500",
      color: "#000",
      "&:hover": {
        background: "#f0eef7",
      },
      "&:active": {
        background: "#f0eef7",
      },
    }),
  };

  const labelStyles = {
    fontSize: ".8rem",
    fontWeight: "400",
    color: "#59429B",
    display: "block",
    marginBottom: "11px",
  };

  const errorStyles = {
    fontSize: ".7rem",
    fontWeight: "400",
    textAlign: "left",
    marginTop: "5px",
    color: "#d32f2f",
  };

  const valueStyle = {
    color: `"#7964b5"}`,
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    margin: "0",
    padding: "10px 15px",
    fontSize: "0.85rem",
    fontWeight: "500",
    position: "absolute",
    top: "0",
    left: "0",
  };

  return (
    <div style={{ position: "relative" }}>
      {label && <Typography color="brandColor.500" fontSize=".85rem" mb="5px">{label}</Typography>}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) => {
          return (
            <Select
              isClearable={isClearable !== undefined ? isClearable : true}
              isSearchable={isSearchable !== undefined ? isSearchable : true}
              isDisabled={isDisabled}
              options={options}
              getOptionLabel={(option) => option[optLabelKey]}
              getOptionValue={(option) => option[optValueKey]}
              onChange={unControlledOnChange ? unControlledOnChange : onChange}
              styles={customStyles}
              placeholder={placeholder}
              value={value}
              isLoading={isLoading}
              menuPlacement={menuPlacement}
            />
          );
        }}
      />
      {
        customErrorMessage && (
        <p style={errorStyles}>{customErrorMessage}</p>
        )
      }
      {formState.errors[name]?.message && (
        <p style={errorStyles}>{formState.errors[name]?.message}</p>
      )}
    </div>
  );
};

export default CustomReactSelect;
