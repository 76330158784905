import React, { useState, useEffect } from "react";
// import { useQuery, useQueryClient } from "react-query";

// material ui import
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { getBellNotifications } from "../../../api";
import { getUser } from "../../../utils/localStorage";
// import { onMessageListener } from "../../../config/firebase";

// local import
// import { onMessageListener } from "../../../config/firebase";

const BellNotification = () => {
  const user = getUser();
  // const { data, isError, error } = useQuery(
  //   "notifications",
  //   async ({ signal }) => {
  //     try {
  //       const res = await getBellNotifications(user?.user_id, signal);

  //       return {
  //         notifications: res.data.data,
  //         active_count: res.data.data.length,
  //       };
  //     } catch (err) {
  //       return err;
  //     }
  //   }
  // );
  // const queryClient = useQueryClient();
  const [notifications, setNotifications] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // onMessageListener()
  //   .then((payload) => {
  //     console.log(payload);
  //   })
  //   .catch((err) => console.log("failed: ", err));

  // onMessageListener()
  //   .then((payload) => {
  //     console.log(payload);
  //   })
  //   .catch((err) => console.log("fbase: ", err));

  // useEffect(() => {
  //   if (data) setNotifications(data);
  //   return () => queryClient.cancelQueries(["notifications"]);
  // }, [data]);

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ padding: "4px" }}>
        <Badge badgeContent={notifications?.active_count} color="error">
          <NotificationsNoneIcon
            sx={{
              fontSize: 25,
              color: "common.white",
            }}
          />
        </Badge>
      </IconButton>
      {notifications?.length > 0 && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiPaper-root": {
              padding: 0,
              borderRadius: 1,
            },
          }}
          PaperProps={{
            elevation: 0,
            padding: 0,
            borderradius: 1,
            sx: {
              overflow: "unset",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {notifications.notifications.map((item) => (
            <MenuItem
              key={item.id}
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Box
                sx={{
                  backgroundColor:
                    item.visit === 0 ? "blue.900" : "brandColor.900",
                  padding: 1,
                  borderRadius: "5px",
                  width: 320,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: ".85rem",
                    color: "brandColor.500",
                    marginBottom: "5px",
                    fontWeight: 500,
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "0.75rem",
                    color: "#000",
                    whiteSpace: "break-spaces",
                    marginBottom: "5px",
                  }}
                >
                  {item.highlight}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "0.8rem",
                    color: "brandColor.400",
                    fontWeight: 600,
                  }}
                >
                  Click here
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default BellNotification;
