import React, { useState, useEffect, useContext } from "react";

// import { generateFirebaseToken } from "../config/firebase";

// import { getCompanyProfile } from "../api";
import { fetchLeaveList, getTimesheetLock } from "../api";
import { showError } from "../utils/api_response_swal";
import { getUser } from "../utils/localStorage";

export const DataContext = React.createContext({});

export const DataContextProvider = ({ children }) => {
  const [companyProfile, setCompanyProfile] = useState(null);
  const [currentFlag, setCurrentFlag] = useState("PH");
  const [tokenNotification, setTokenNotification] = useState(null);
  const [leaveTypeContainer, setLeaveTypeContainer] = useState([]);
  const [hiredDetails, setHiredDetails] = useState();
  const [timeLock, setTimeLock] = useState({ from: null, status: "OPEN", to: null });

  const controller = new AbortController();
  const fromLocal = getUser();
  // generateFirebaseToken(setTokenNotification);

  // useEffect(() => {
  //   const controller = new AbortController();

  // getCompanyProfile(controller)
  //   .then((res) => {
  //     // console.log(res);
  //     if (res.status === 200) {
  //       setCompanyProfile(res.data[0]);
  //       // const favicon = document.getElementById("my-favicon");
  //       // favicon.setAttribute("href", res.data.data.favicon);
  //     }
  //   })
  //   .catch((error) => {
  //     console.log(error.response);
  //   });
  // return () => {
  //   controller.abort();
  // };

  // }, []);

  const handleInitialLoad = async () => {
    try {
      const leaveTypeRes = await fetchLeaveList(fromLocal.token, controller);
      setLeaveTypeContainer(leaveTypeRes.data.data);
    } catch (error) {
      console.log(error.response);
    }
  }

  useEffect(() => {
    fromLocal?.user_id && handleInitialLoad();
    return () => controller.abort();
  }, [fromLocal?.user_id])

  return (
    <DataContext.Provider
      value={{
        companyProfile,
        currentFlag,
        setCurrentFlag,
        tokenNotification,
        leaveTypeContainer,
        setLeaveTypeContainer,
        hiredDetails,
        setHiredDetails,
        timeLock,
        setTimeLock
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => {
  return useContext(DataContext);
};
