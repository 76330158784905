import React from "react";
import { Outlet } from "react-router-dom";

import RedirectComponent from "../general/RedirectComponent";
import NoMenuLayout from "../layout/NoMenuLayout";
import { useAuth } from "../../utils/useAuth";

const PublicRoutes = ({
  hasLayout = true,
  hasBgName = false,
  notFound = false,
}) => {
  const user = useAuth();

  // && user?.new !== 1

  if (user && !notFound && user?.new !== 1) {
    return <RedirectComponent />;
  }

  // if (user) return <Navigate to="/redirect" />;

  return hasLayout ? (
    <NoMenuLayout hasBgName={hasBgName}>
      <Outlet />
    </NoMenuLayout>
  ) : (
    <Outlet />
  );
};

export default PublicRoutes;
