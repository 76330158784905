import React from "react";
import { Oval } from "react-loader-spinner";

const PageLoader = ({ color1, color2, containerHeight }) => {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        width: "100%",
        height: containerHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Oval
        height={50}
        width={50}
        strokeWidth={4}
        color={color1}
        secondaryColor={color2}
      />
    </div>
  );
};

export default PageLoader;
